.app {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;
}

.app-header {
    background-image: linear-gradient(180deg, $gray-200 0, transparent 100%);
    padding: 1rem 0;
    position: relative;
    z-index: 1;

    .app-brand {
        margin: 0 auto;
        max-width: rem(200);
        text-align: center;
    }
}

.app-content {
    background-image: linear-gradient(180deg, transparent 75%, $gray-200 100%);
    flex-grow: 1;
}

.app-footer {
    background-color: $gray-200;
    padding: rem(32) 0;

    .copyright {
        font-size: rem(14);
        text-align: center;
    }
}
