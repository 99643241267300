.page-header {
    padding: rem(40) 0 rem(60);
    text-align: center;

    .page-title {
        font-weight: 200;
    }

    .page-subtitle {
        font-size: 1.25rem;
    }
}
