/** Font Awesome Free */
@import '~@fortawesome/fontawesome-free/css/all.css';

/** Google Web Fonts */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;400;700&display=swap');

/** Bootstrap */
@import '~bootstrap';

/** Select2 */
@import '~select2/src/scss/core';

/** TinyMCE */
@import '~tinymce/skins/ui/oxide/skin.css';
@import '~tinymce/skins/ui/oxide/content.css';
@import '~tinymce/skins/content/default/content.css';
