html,
body {
    font-family: "Outfit", sans-serif;
    height: 100%;
    position: relative;
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

img {
    max-width: 100%;
    vertical-align: top;
}

p {
    line-height: 1.2;
    margin: 0;
}

* + h1,
* + h2,
* + h3,
* + h4,
* + p {
    margin-top: 0.5em;
}

a[rel=external]::after {
    content: "\f35d";
    font-family: "Font Awesome 5 Free";
    font-size: 0.75rem;
    font-weight: 900;
    margin-left: 0.2em;
}

a[rel=external].skip-external::after {
    content: none;
}

.del {
    text-decoration: line-through;
    color: $red;
    font-weight: bold;
}
